




import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    PagePresetGuest: () => import("@/layouts/presets/guest.vue"),
  },
})
export default class PageHome extends Vue {}
